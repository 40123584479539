import React from "react";
import { useTranslation } from "react-i18next";

export const BtnDownload = (props) => {
  const {t}=useTranslation()

  return (
    <a
      className={` btn btn-brochure ${
        props.colors}`}
      download="JC Innovation - Brochure de Servicios"
      href="/JC-Innovation__ServiciosBrochure.pdf"
    >
      <i className="fa-solid fa-download"></i>
      <span>{t('BROCHURE DE SERVICIOS')}</span>
    </a>
  );
};
